<template>
  <div>
    <div class="maintenance-mod">
      <div class="header">
        <h3 class="tit">{{title}}</h3>
        <div>
          <a-button size="small" type="primary" class="mr-16" @click="addHandle"><i class="e-icon add"></i>新增{{curName}}</a-button>
          <a-button size="small" class="mr-16" @click="editHandle"><i class="e-icon edit"></i>编辑</a-button>
          <a-button size="small" class="mr-16" @click="deleteHandle"><i class="e-icon delete"></i>删除</a-button>
          <a-button size="small" @click="openSortProp"><a-icon style="transform: rotate(90deg)" type="swap" />排序</a-button>
        </div>
      </div>
      <div class="main">
        <div v-if="list.length == 0">
          <a-empty :image="simpleImage" />
        </div>
        <div class="check-item-wrap">
          <span class="check-item" :style="{width: item.categoryName.length <= 5 ? '100px' : '160px'}" v-for="item in list" :key="item.categoryId">
            <a-checkbox :checked="item.checked" @change="e => onChange(e, item)">
              {{ item.categoryName }}
            </a-checkbox>
          </span>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <a-modal v-model="addVisible" :title="'新增' + curName" centered @ok="addOk">
      <a-form-model :model="addForm" :label-col="{span: 8}" :wrapper-col="{span: 12}">
        <a-form-model-item :label="curName + '名称'">
          <a-input v-model="addForm.name" :maxLength="10" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 编辑 -->
    <a-modal v-model="editVisible" :title="'编辑' + curName" centered @ok="editOk">
      <a-form-model :model="editForm" :label-col="{span: 8}" :wrapper-col="{span: 12}">
        <a-form-model-item :label="curName + '名称'">
          <a-input v-model="editForm.name" :maxLength="10" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 排序 -->
    <a-modal v-model="sortVisible" :title="curName + '排序'" centered @ok="sortOk">
      <p style="color: #999">您可以直接通过拖拽调节顺序</p>
      <draggable
        class="drag-list-group"
        tag="div"
        v-model="newSortList"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <div
            class="drag-size-item"
            v-for="element in newSortList"
            :key="element.categoryId"
          >
            {{ element.categoryName }}
          </div>
        </transition-group>
      </draggable>
      <a-button type="link" style="margin-top: 8px; padding: 0" @click="sortRest">重置顺序</a-button>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import draggable from 'vuedraggable'
import {
  dataItemList, 
  dataCategoryAdd,
  dataCategoryUpdate,
  dataCategoryRemove,
  dataCategorySort
  } from '@/api/system'
import { mapGetters } from 'vuex'
// 名称表
const names = {
  '301': '颜色',
  '302': '尺码'
}

export default {
  name: 'data-maintenance-item',
  props: [
    'title', 'pId', 'type'
  ],
  components: {
    draggable
  },
  computed: {
    ...mapGetters(['adminId']),
    curName: function() {
      if (this.type) return names[this.type] || ''
      return ''
    }
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // 列表
      list: [
        // {
          // categoryCreateAdminId: 25
          // categoryCreateTime: "2021-08-03T11:56:50"
          // categoryId: 504
          // categoryLastUpdateAdminId: null
          // categoryLastUpdateTime: null
          // categoryName: "白色"
          // categoryProbabilityPercent: null
          // categorySequence: 1
          // categoryStatus: "y"
          // categoryType: 301
          // parentId: 503
        // },
      ],
      // 新增
      addVisible: false,
      addForm: {
        name: ''
      },
      // 编辑
      editVisible: false,
      editForm: {
        name: ''
      },
      currentEdit: {},

      // 排序
      sortVisible: false,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      oldSortList: [],
      newSortList: [],

    }
  },
  created() {
    console.log(this.title, this.pId, this.type)
    this.dataItemList()
  },
  methods: {
    // 列表
    dataItemList() {
      dataItemList({parentId: this.pId}).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.list = res.data.map(item => {
            return {
              ...item,
              checked: false,
            }
          })
        } else {
          this.$message.error(res.msg || '获取' + this.curName + '列表失败')
        }
      }).catch(err => {
        console.error(err);
      })
    },
    // 新增
    addHandle() {
      this.addVisible = true
    },
    addOk() {
      let name = this.addForm.name.trim()
      if (name === '') {
        return this.$message.error('请输入' + this.curName + '名称')
      }
      if (this.list.findIndex(item => item.categoryName === name) >= 0) {
        return this.$message.error('该' + (this.curName || '项') + '已存在')
      }
      this.$showLoading()
      dataCategoryAdd({
        adminId: this.adminId,
        categoryName: name,
        categoryType: this.type,
        parentId: this.pId
      }).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.addVisible = false
          this.addForm.name = ''
          this.$message.success('新增成功')
          this.dataItemList()
        } else {
          this.$message.error(res.msg || '新增失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      })
    },
    // 编辑
    editHandle() {
      let list = this.list.filter(item => item.checked)
      if (list.length !== 1) {
        return this.$message.info('请勾选一项进行编辑')
      }
      this.editVisible = true
      this.currentEdit = list[0]
      this.editForm.name = list[0].categoryName
    },
    editOk() {
      let name = this.editForm.name.trim()
      if (name === '') {
        return this.$message.error('请输入' + this.curName + '名称')
      }
      if (this.list.findIndex(item => item.categoryName === name) >= 0) {
        return this.$message.error('该' + (this.curName|| '项') + '已存在')
      }
      this.$showLoading()
      dataCategoryUpdate({
        adminId: this.adminId,
        categoryId: this.currentEdit.categoryId, 
        categoryName: name,
        categoryType: this.type,
        parentId: this.pId
      }).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.editVisible = false
          this.$message.success('编辑成功')
          let index = this.list.findIndex(item => item.categoryId === this.currentEdit.categoryId)
          this.list[index].categoryName = name
        } else {
          this.$message.error(res.msg || '编辑失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      }) 
    },

    // 删除
    deleteHandle() {
      let list = this.list.filter(item => item.checked)
      if (list.length === 0) {
        return this.$message.info('请先勾选需要删除的' + (this.curName|| '项目'))
      }
      let ids = list.map(item => item.categoryId).join(',')
      this.$confirm({
        title: '操作提示',
        content: '确定删除已勾选的' + (this.curName || '项目') + '吗?',
        centered: true,
        onOk: () => {
          this.$showLoading()
          dataCategoryRemove({
            adminId: this.adminId,
            ids
          }).then(res => {
           this.$hideLoading()
            if (res.code === 200) {
              this.$message.success('删除成功')
              let result = this.list.filter(item => !item.checked)
              this.list = [...result]
            } else {
              this.$message.error(res.msg || '删除失败')
            }
          }).catch(err => {
            this.$hideLoading()
            console.error(err)
          }) 
        }
      })
    },

    // 排序
    openSortProp() {
      this.sortVisible = true
      this.newSortList = [...this.list]
    },
    sortRest() {
      this.newSortList = [...this.list]
    },
    sortOk() {
      console.log('newSortList', this.newSortList)
      this.$showLoading()
      let list = this.newSortList.map((item, index) => {
        return {v1: item.categoryId, v2: index + 1}
      })
      dataCategorySort({
        adminId: this.adminId,
        sortList: list
      }).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.$message.success('排序成功')
          this.sortVisible = false
          this.list = [...this.newSortList]
        } else {
          this.$message.error(res.msg || '排序失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err);
      })
    },

    // 勾选
    onChange(e, item) {
      item.checked = e.target.checked
    },
  }
}
</script>