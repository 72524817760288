<template>
  <div>
    <div class="maintenance-mod">
      <div class="header">
        <h3 class="tit">尺码维护</h3>
        <div>
          <a-button size="small" type="primary" class="mr-16" @click="addHandle"><i class="e-icon add"></i>新增尺码</a-button>
          <a-button size="small" class="mr-16" @click="editHandle"><i class="e-icon edit"></i>编辑</a-button>
          <a-button size="small" class="mr-16" @click="deleteHandle"><i class="e-icon delete"></i>删除</a-button>
          <a-button size="small" @click="openSortProp"><a-icon style="transform: rotate(90deg)" type="swap" />排序</a-button>
        </div>
      </div>
      <div class="main">
        <div v-if="list.length == 0">
          <a-empty :image="simpleImage" />
        </div>
        <div class="check-item-wrap">
          <span class="check-item" :style="{width: item.name.length <= 5 ? '100px' : '160px'}" v-for="item in list" :key="item.id">
            <a-checkbox :checked="item.checked" @change="e => onChange(e, item)">
              {{ item.name }}
            </a-checkbox>
          </span>
        </div>
      </div>
    </div>

    <!-- 新增尺码 -->
    <a-modal v-model="addVisible" title="新增尺码" centered @ok="addOk">
      <a-form-model :model="addForm" :label-col="{span: 8}" :wrapper-col="{span: 12}">
        <a-form-model-item label="尺码名称">
          <a-input v-model="addForm.name" :maxLength="10" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 编辑尺码 -->
    <a-modal v-model="editVisible" title="编辑尺码" centered @ok="editOk">
      <a-form-model :model="editForm" :label-col="{span: 8}" :wrapper-col="{span: 12}">
        <a-form-model-item label="尺码名称">
          <a-input v-model="editForm.name" :maxLength="10" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 尺码排序 -->
    <a-modal v-model="sortVisible" title="尺码排序" centered @ok="sortOk">
      <p style="color: #999">您可以直接通过拖拽调节顺序</p>
      <draggable
        class="drag-list-group"
        tag="div"
        v-model="newSortList"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <div
            class="drag-size-item"
            v-for="element in newSortList"
            :key="element.id"
          >
            {{ element.name }}
          </div>
        </transition-group>
      </draggable>
      <a-button type="link" style="margin-top: 8px; padding: 0" @click="sortRest">重置顺序</a-button>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import draggable from 'vuedraggable'
import {
  sizeList,
  addSize,
  updateSize,
  deleteSize,
  sizeSort
} from '@/api/goods'

export default {
  name: 'size-maintenance',
  components: {
    draggable
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      // 尺码列表
      list: [
        // {
        //   id: 1,
        //   name: 'S',
        //   sequence: 1,
        //   checked: false,
        // },
      ],
      // 新增尺码
      addVisible: false,
      addForm: {
        name: ''
      },
      // 编辑尺码
      editVisible: false,
      editForm: {
        name: ''
      },
      currentEdit: {},

      // 排序
      sortVisible: false,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      oldSortList: [],
      newSortList: [],

    }
  },
  created() {
    this.sizeList()
  },
  methods: {
    // 颜色列表
    sizeList() {
      sizeList().then(res => {
        console.log(res)
        if (res.code === 200) {
          this.list = res.data.map(item => {
            return {
              ...item,
              checked: false,
            }
          })
        } else {
          this.$message.error(res.msg || '获取尺码列表失败')
        }
      }).catch(err => {
        console.error(err);
      })
    },
    // 新增尺码
    addHandle() {
      this.addVisible = true
    },
    addOk() {
      let name = this.addForm.name.trim()
      if (name === '') {
        return this.$message.error('请输入尺码名称')
      }
      if (this.list.findIndex(item => item.name === name) >= 0) {
        return this.$message.error('该尺码已存在')
      }
      this.$showLoading()
      addSize({name}).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.addVisible = false
          this.addForm.name = ''
          this.$message.success('新增成功')
          this.sizeList()
        } else {
          this.$message.error(res.msg || '新增失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      })
    },
    // 编辑尺码
    editHandle() {
      let list = this.list.filter(item => item.checked)
      if (list.length !== 1) {
        return this.$message.info('请勾选一个尺码进行编辑')
      }
      this.editVisible = true
      this.currentEdit = list[0]
      this.editForm.name = list[0].name
    },
    editOk() {
      let name = this.editForm.name.trim()
      if (name === '') {
        return this.$message.error('请输入尺码名称')
      }
      if (this.list.findIndex(item => item.name === name) >= 0) {
        return this.$message.error('该尺码已存在')
      }
      this.$showLoading()
      updateSize({id: this.currentEdit.id, name}).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.editVisible = false
          this.$message.success('编辑成功')
          let index = this.list.findIndex(item => item.id === this.currentEdit.id)
          this.list[index].name = name
        } else {
          this.$message.error(res.msg || '编辑失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      }) 
    },

    // 删除尺码
    deleteHandle() {
      let list = this.list.filter(item => item.checked)
      if (list.length === 0) {
        return this.$message.info('请先勾选需要删除的尺码')
      }
      let ids = list.map(item => item.id).join(',')
      this.$confirm({
        title: '操作提示',
        content: '确定删除已勾选的尺码吗?',
        centered: true,
        onOk: () => {
          this.$showLoading()
          deleteSize({ids}).then(res => {
           this.$hideLoading()
            if (res.code === 200) {
              this.$message.success('删除成功')
              let result = this.list.filter(item => !item.checked)
              this.list = [...result]
            } else {
              this.$message.error(res.msg || '删除失败')
            }
          }).catch(err => {
            this.$hideLoading()
            console.error(err)
          }) 
        }
      })
    },

    // 尺码排序
    openSortProp() {
      this.sortVisible = true
      this.newSortList = [...this.list]
    },
    sortRest() {
      this.newSortList = [...this.list]
    },
    sortOk() {
      console.log('newSortList', this.newSortList)
      this.$showLoading()
      let list = this.newSortList.map((item, index) => {
        return {id: item.id, seq: index + 1}
      })
      sizeSort({list}).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          this.$message.success('排序成功')
          this.sortVisible = false
          this.list = [...this.newSortList]
        } else {
          this.$message.error(res.msg || '排序失败')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err);
      })
    },

    // 勾选尺码
    onChange(e, item) {
      item.checked = e.target.checked
    },
  }
}
</script>