<template>
  <div>
    <!-- 尺码维护 -->
    <!-- <size /> -->
    <!-- 颜色维护 -->
    <!-- <color /> -->
    <div style="background: #fff;overflow:hidden; padding: 40px 0;" v-if="records.length === 0">
      <a-empty :image="simpleImage" />
    </div>
    <div>
      <item 
        v-for="item in records" :key="item.categoryId" 
        :p-id="item.categoryId"
        :title="item.categoryName"
        :type="item.categoryType"
      />
    </div>
    
  </div>
</template>

<script>
import size from './size.vue'
import color from './color.vue'
import item from './item.vue'
import {dataCategoryList} from '@/api/system'
import { Empty } from 'ant-design-vue'
export default {
  name: 'static-data-goods-maintenance',
  components: {
    size,
    color,
    item
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      records: []
    }
  },
  created() {
    this.dataCategoryList();
  },
  methods: {
    /**
     * 获取分类列表
     * 颜色维护 301  尺码维护 302
    */
    dataCategoryList() {
      dataCategoryList({
        current: 1,
        size: 2,
        categoryTypes: '302,301'
      }).then(res => {
        console.log(res);
        if (res.code === 200) {
          let records = res.data.records
          this.records = records
          
        }
      }).catch(err => {
        console.error(err);
      })
    }
  }
}
</script>

<style lang="less">
.maintenance-mod {
  border: 1px solid #E6E6E6;
  margin-bottom: 24px;
  .header {
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-bottom: 1px solid #E6E6E6;
    justify-content: space-between;
    .tit {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  .main {
    padding: 32px 20px;
    background: #fff;
    .check-item-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .check-item {
      display: inline-block;
      margin-bottom: 16px;
    }
  }
}
</style>